<template>
  <div>
    <h1>待办事项应用程序</h1>
    <input v-model="newTodo" @keyup.enter="addTodo" placeholder="添加待办事项">
    <button @click="addTodo">添加</button>
    <ul>
      <li v-for="todo in todos" :key="todo.id">
        {{ todo.text }}
        <button @click="removeTodo(todo.id)">删除</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newTodo: '',
      todos: [],
      nextId: 1
    }
  },
  methods: {
    addTodo() {
      const trimmedText = this.newTodo.trim();
      if (trimmedText) {
        this.todos.push({ id: this.nextId++, text: trimmedText });
        this.newTodo = '';
      }
    },
    removeTodo(id) {
      this.todos = this.todos.filter(todo => todo.id !== id);
    }
  }
};
</script>

<style scoped>
/* 在这里添加您的样式 */
</style>
